import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'sincronigest';
  // dockMenu: MenuItem[];
  speed: MenuItem[];
  isHovered: { [key: string]: boolean } = {}; // Rastreia o estado de hover dos ícones
  selectedItemUrl: string = ''; // Rastreia o ícone selecionado

  constructor(private router: Router, private config: PrimeNGConfig) {
    // this.dockMenu = [
    //   {
    //     label: 'Home',
    //     icon: "assets/menu-left/imagem/dock/home.svg",
    //     url: '/'
    //   },
    //   {
    //     label: 'Shop',
    //     icon: "assets/menu-left/imagem/dock/shop.svg",
    //     url: '/produtos'
    //   },
    //   {
    //     label: 'Usuário',
    //     icon: "assets/menu-left/imagem/dock/user.svg",
    //     url: '/usuarios'
    //   },
    //   {
    //     label: 'Chart',
    //     icon: "assets/menu-left/imagem/dock/chart.svg",
    //     url: '/graficos'
    //   },
    //   {
    //     label: 'Configuração',
    //     icon: "assets/menu-left/imagem/dock/config.svg",
    //     url: '/configuracao'
    //   },
    // ];

    this.speed = [
      {
        icon: 'pi pi-home',
        routerLink: ['/'],
        tooltipOptions: {
          tooltipLabel: 'Inicio',
          tooltipPosition: 'right',
        },
      },
      {
        icon: 'pi pi-shopping-bag',
        routerLink: ['/produtos'],
        tooltipOptions: {
          tooltipLabel: 'Produtos',
        },
      },
      {
        icon: 'pi pi-users',
        routerLink: ['/clientes'],
        tooltipOptions: {
          tooltipLabel: 'Clientes',
        },
      },
      {
        icon: 'pi pi-dollar',
        routerLink: ['/lancamentos'],
        tooltipOptions: {
          tooltipLabel: 'Lançamentos',
        },
      },
      {
        icon: 'pi pi-user',
        routerLink: ['/usuarios'],
        tooltipOptions: {
          tooltipLabel: 'Usuários',
        },
      },
      {
        icon: 'pi pi-chart-line',
        routerLink: ['/graficos'],
        tooltipOptions: {
          tooltipLabel: 'Gráficos',
        },
      },
      {
        icon: 'pi pi-cog',
        routerLink: ['/configuracao'],
        tooltipOptions: {
          tooltipLabel: 'Configuração',
        },
      },
    ];
  }

  ngOnInit() {
    this.config.setTranslation({
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
    });
  }

  public event(url: string) {
    this.router.navigate([url]);
  }
}
