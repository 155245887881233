import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

import { DockModule } from 'primeng/dock';
import { SpeedDialModule } from 'primeng/speeddial';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageModule } from 'primeng/image';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';

import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import ptBR from '@angular/common/locales/pt';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    DockModule,
    BreadcrumbModule,
    MenuModule,
    ButtonModule,
    SpeedDialModule,
    TableModule,
    CardModule,
    PanelModule,
    ReactiveFormsModule,
    ImageModule,
    CalendarModule,
    DropdownModule,
    IMaskModule,
    SelectButtonModule,
    PaginatorModule,
  ],
  exports: [
    SharedModule,
    DockModule,
    HttpClientModule,
    BreadcrumbModule,
    MenuModule,
    ButtonModule,
    SpeedDialModule,
    TableModule,
    CardModule,
    PanelModule,
    ReactiveFormsModule,
    ImageModule,
    CalendarModule,
    DropdownModule,
    IMaskModule,
    SelectButtonModule,
    PaginatorModule,
  ],
})
export class CoreModule {
  constructor() {
    registerLocaleData(ptBR);
  }
}
