<!-- <p-dock [model]="dockMenu" position="left" style="display:inline-block;width: 10%;">
  <ng-template pTemplate="item" let-item>
    <img [src]="item.icon" [alt]="item.label" (click)="event(item.url)" width="20px" class="dockicon"
      [ngClass]="{'hovered': isHovered[item.url], 'selected': item.url === selectedItemUrl}">
  </ng-template>
</p-dock> -->
<div class="menuGlobal">
  <div class="menu">
    <p-speedDial class="speedDial" [model]="speed" direction="down" [transitionDelay]="50" showIcon="pi pi-bars"
      hideIcon="pi pi-times">
    </p-speedDial>
  </div>

  <div class="logo">
    <a href="/">
      <p-image src="../assets/menu-left/imagem/logo.png" alt="sincronigest" width="180" href=""></p-image>
    </a>
  </div>
</div>

<div class="routerOutlet">
  <router-outlet></router-outlet>
</div>

<footer>
  <app-footer></app-footer>
</footer>
