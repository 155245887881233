import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
// import jwt_decode from 'jwt-decode';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { TokenDecode } from '../models/token-decode.model';
// import { catchError, map } from 'rxjs/operators';
// import { Pass } from 'src/app/shared/models/pass';
// import { RotasPermitidas } from 'src/app/shared/models/rotas-permitidas';
// import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //   tokenAtualizado = new EventEmitter();

  //   constructor(
  //       private http: HttpClient
  //   ) { }

  // deslogar() {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.href = environment.FRONTLINE_URL;
  // }

  //   public async setarTokenUsuario(token: string) {
  //       sessionStorage.setItem('localUser', token);
  //   }

  // public async setarPassUsuario() {
  //   let passUsuario = await this.obterPermissoes().toPromise()

  //   let pass = btoa(JSON.stringify(passUsuario));
  //   let hashModificada = pass.replace(/s/g, "pT").replace(/e/g, "tK").replace(/2/g, "8j");
  //   sessionStorage.setItem("pass", hashModificada);
  // }

  // public async setarRotasPermitidas() {
  //   let rotasPermitidas = await this.obterRotasPermitidas().toPromise()
  //   sessionStorage.setItem("route", JSON.stringify(rotasPermitidas))
  // }

  // public obterLocalPass(): Pass {
  //   let passUsuario = sessionStorage.getItem("pass");
  //   let hashRestaurada = passUsuario.replace(/8j/g, "2").replace(/tK/g, "e").replace(/pT/g, "s");
  //   return JSON.parse(atob(hashRestaurada));
  // }

  // public obterLocalRoute(): RotasPermitidas[] {
  //   let rotas = sessionStorage.getItem("route");
  //   return JSON.parse(rotas);
  // }

  //   public verificarTokenValido() {
  //       try {
  //               if (!this.tokenUsuarioExpirado()) return of(true);
  //               return this.atualizarToken();
  //           } catch (error) {
  //               return of(false);
  //      }
  //   }

  // public existeTokenUsuario(): boolean {
  //   return this.obterTokenUsuario() ? true : false;
  // }

  // public obterTokenUsuario(): string {
  //   var localUser = JSON.parse(sessionStorage.getItem('localUser'))
  //   return localUser.token;
  // }

  // public obterDadosDoToken(): TokenDecode {
  //   var token = this.obterTokenUsuario()
  //   var tokenDecode = jwt_decode<TokenDecode>(token.toString())
  //   return tokenDecode;
  // }

  //   public tokenUsuarioExpirado() {
  //       var token = this.obterTokenUsuario()
  //       var tokenDecode = jwt_decode<TokenDecode>(token.toString())
  //       if (Date.now() >= (tokenDecode.exp * 1000) - 300000) return true;
  //       return false;
  //   }

  // private atualizarToken() {
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json')
  //     .set('auth', `Bearer ${(this.obterTokenUsuario())}`)
  //   return this.http.post<any>(`${environment.GUARDIAN_URL}auth/refresh`, null, { headers }).pipe(
  //     map(data => {
  //       this.setarTokenUsuario(`{"token":"${data.token}"}`);
  //       this.tokenAtualizado.emit(null);
  //       return true;
  //     }),
  //     catchError(() => of(false)))
  // }

  // public atualizaTokenRequest() {
  //   return this.atualizarToken().toPromise();
  // }

  // private obterPermissoes(): Observable<Pass> {
  //   const decode = this.obterDadosDoToken()
  //   return this.http.get<Pass>(`${environment.baseUrl_GSI}usuario/${decode.login}`).pipe(
  //     map((data) => {
  //       let pass: Pass = {
  //         nome: data.nome,
  //         cadOrgao: data.cadOrgao,
  //         cadUnidOrc: data.cadUnidOrc,
  //         cadUnidDesp: data.cadUnidDesp,
  //         nivel: data.nivel,
  //         codigoGrupo: data.codigoGrupo,
  //         codigoOperadorSistema: data.codigoOperadorSistema,
  //         cadastrarNovoUsuario: data.cadastrarNovoUsuario,
  //       };
  //       return pass;
  //     }),
  //     catchError((error) => throwError(error))
  //   )
  // }


  // private obterRotasPermitidas(): Observable<RotasPermitidas[]> {
  //   return this.http.get<RotasPermitidas[]>(`${environment.baseUrl_GSI}util/acesso-transacao`).pipe(
  //     map((data) => {
  //       let rotas = new Array<RotasPermitidas>();
  //       data.forEach((element) => {
  //         rotas.push({
  //           codigoGrupo: element.codigoGrupo,
  //           codigoItemMenu: element.codigoItemMenu,
  //           codigoModulo: element.codigoModulo,
  //           codigoOperador: element.codigoOperador,
  //           codigoTransacao: element.codigoTransacao,
  //           nomeItemMenu: element.nomeItemMenu,
  //           nomeModulo: element.nomeModulo,
  //           nomeTransacao: element.nomeTransacao,
  //           rowId: element.rowId,
  //           rowIdOperadorGrupo: element.rowIdOperadorGrupo,
  //         });
  //       });

  //       return rotas;
  //     }),
  //     catchError(error => {
  //       return throwError(error)
  //     })
  //   );
  // }

}
