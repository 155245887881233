import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams, } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('auth/refresh')) return next.handle(request);

    const token = 'saffasfsasaf324421142';

    if (token == null) {
      var requestClearParams = request.clone({
        params: this.limparParametros(request.params)
      });

      return next.handle(requestClearParams)
    }

    if (token) {
      var requestComToken = request.clone({
        headers: request.headers.set(
          "user",
          "matheus",
        )
        .set(
          "password",
          "aut"
        ),

        params: this.limparParametros(request.params)
      });

      return next.handle(requestComToken);
    }

    return next.handle(request);
  }

  limparParametros(params: HttpParams) {
    let cleanedParams = new HttpParams();

    params.keys().forEach(x => {
      const paramValue = params.get(x);
      if (paramValue && paramValue !== 'undefined' && paramValue !== 'null') {
        cleanedParams = cleanedParams.append(x, paramValue);
      }
    });

    return cleanedParams;
  }
}
