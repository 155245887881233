import { HomeModule } from './core/components/home/shared/home.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./core/components/home/shared/home.module').then(p => p.HomeModule) },
  { path: 'produtos', loadChildren: () => import('./pages/produtos/produtos.module').then(p => p.ProdutosModule) },
  { path: 'lancamentos', loadChildren: () => import('./pages/lancamentos/lancamentos.module').then(p => p.LancamentosModule) },
  { path: 'clientes', loadChildren: () => import('./pages/clientes/clientes.module').then(p => p.ClientesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
